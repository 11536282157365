<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>Цена</v-toolbar-title>
                <v-spacer/>
                <v-switch
                    v-model="entity.popular"
                    label="Популярное предложение"
                    hide-details
                />
                <v-spacer/>
                <v-switch
                    v-model="entity.enabled"
                    label="Активен"
                    hide-details
                />
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.group"
                    label="Размер группы"
                    :items="groups"
                    item-text="title"
                    item-value="value"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                    v-model="entity.age"
                    label="Возраст"
                    :items="ages"
                    item-text="title"
                    item-value="value"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-select
                v-model="entity.subject"
                label="Предмет"
                :items="subjects"
                item-text="title"
                item-value="value"
                outlined
            />
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.duration"
                    label="Продолжительность"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.quantity"
                    label="Количество уроков"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.price"
                    label="Цена"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.priceForLesson"
                    label="Цена за один урок"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }">
                <v-text-field
                    v-model="entity.href"
                    label="Ссылка для оплаты"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }">
                <v-text-field
                    v-model="entity.hrefRussian"
                    label="Ссылка для оплаты в России"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-row
                align="center"
                justify="space-between"
                class="mt-4 mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">Сохранить</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">Удалить</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc } from '../../helpers';
    import { AGE, AGE_TITLE, GROUP, GROUP_TITLE, SUBJECT, SUBJECT_TITLE } from '../../vars';

    export default {
        name: 'PricesEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            imageSrc,
            valid: true,
            AGE,
            AGE_TITLE,
            GROUP,
            GROUP_TITLE,
            SUBJECT,
            SUBJECT_TITLE
        }),
        computed: {
            ...mapState('prices', ['entity']),
            groups() {
                const groups = [];
                for(const item in GROUP_TITLE) {
                    groups.push({
                        value: item,
                        title: GROUP_TITLE[item]
                    })
                }
                return groups
            },
            ages() {
                const ages = [];
                for(const item in AGE_TITLE) {
                    ages.push({
                        value: item,
                        title: AGE_TITLE[item]
                    })
                }
                return ages
            },
            subjects() {
                const subjects = [];
                for(const item in SUBJECT_TITLE) {
                    subjects.push({
                        value: item,
                        title: SUBJECT_TITLE[item]
                    })
                }
                return subjects
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('prices/get', { id: this.$route.params.id });
            } else {
                await store.commit('prices/CLEAR_ENTITY');
            }
        },
        methods: {
            async setPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('prices/SET_ENTITY', this.entity);
                    const response = await store.dispatch('prices/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'prices-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('prices/delete', { id: this.entity._id });
                await this.$router.push({ name: 'prices-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .prices__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
